<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogVisible" title="添加无票收入" width="600px">
    <el-form :model="form" label-width="157px" size="small" :rules="rules" ref="dataForm">
      <el-form-item label="含税金额:" v-if="list.length == 1" prop="hsJe">
        <qzf-input v-model:num="form.hsJe" class="w-240 m-rl-5" />
      </el-form-item>
      <el-form-item label="含税金额区间:" v-else prop="hsJeSt">
        <qzf-input v-model:num="form.hsJeSt" class="w-114 ml-5" />
        &nbsp;-&nbsp;
        <qzf-input v-model:num="form.hsJeEd" class="w-114" />
      </el-form-item>
      <el-form-item label="货物或应税劳务、服务:" prop="nameId">
        <select-common placeholder="请选择货物或应税劳务、服务" v-model:modelValue="form.nameId" :options="hwOptions"
          class="w-240 m-rl-5" />
      </el-form-item>
      <el-form-item label="商品编码:" prop="ssflbm">
        <select-common placeholder="请选择商品编码" v-model:modelValue="form.ssflbm" :options="options" class="w-240 m-rl-5"
          @selectChange="changeBig" />
      </el-form-item>
      <el-form-item label="税目:" prop="smId">
        <select-common placeholder="请选择税目" v-model:modelValue="form.smId" labelKey="name" valueKey="id"
          :options="smOptions" class="w-240 m-rl-5" />
      </el-form-item>
      <el-form-item label="结算方式:" prop="jsFs">
        <el-radio-group v-model="form.jsFs" @change="changeSettle" :disabled="list.length > 1">
          <el-radio :label="1">挂帐</el-radio>
          <el-radio :label="2">现金结算</el-radio>
          <el-radio :label="3">银行</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="list.length == 1">
        <el-form-item label="客户名称:" v-if="form.jsFs == 1" prop="corpId">
          <div style="width:249px">
            <corp-list v-model:corpId="form.corpId" type="1" :comId="list[0].comId"></corp-list>
          </div>
        </el-form-item>
        <el-form-item label="结算科目:" v-else prop="subjectId">
          <subject-list width="240" v-model:subjectId="form.subjectId" v-model:fzhsItemId="form.fzhsId"
            :codes="$findCode(code, list[0].comId)" :comId="list[0].comId"></subject-list>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="结算科目:">
          <select-common placeholder="请选择结算科目" v-model:modelValue="settleName" :options="settleOptions"
            class="w-240 m-rl-5" disabled />
        </el-form-item>
      </div>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" size="small" @click="saveTicket" :loading="loading">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus/es/components';
import { ssflbmOptions } from '@/components/ssflbm/constants.js'
import { batchWpsr } from '@/api/company'
const props = defineProps({
  period: {
    type: String,
    default: ""
  }
})
const { proxy } = getCurrentInstance()
const dialogVisible = ref(false)
const form = ref({})
const options = ssflbmOptions.filter(v => v.value != '60')
const smOptions = computed(() => {
  const list = proxy.$store.getters["commons/taxCategorys"]
  let selectSsflbm = options.find(v => v.value == form.value.ssflbm)
  if (!selectSsflbm) return list
  let matchText = selectSsflbm.label == '销售服务' ? '服务' : selectSsflbm.label
  const excludeKeywords = ['货物', '劳务', '服务', '不动产', '无形资产'];
  const filteredList = list.filter(item => {
    const name = item.name || '';
    return name.includes(matchText) || !excludeKeywords.some(keyword => name.includes(keyword))
  });
  return filteredList;
})
const rules = ref({
  hsJe: [
    { required: true, message: "请输入含税金额", trigger: "change" }
  ],
  hsJeSt: [
    { required: true, message: "请输入含税金额区间", trigger: "change" }
  ],
  nameId: [
    { required: true, message: "请选择货物或应税劳务、服务", trigger: "change" }
  ],
  ssflbm: [
    { required: true, message: "请选择商品编码", trigger: "change" }
  ],
  smId: [
    { required: true, message: "请选择税目", trigger: "change" }
  ],
  jsFs: [
    { required: true, message: "请选择结算方式", trigger: "change" }
  ],
  corpId: [
    { required: true, message: "请选择客户", trigger: "change" }
  ],
  subjectId: [
    { required: true, message: "请选择结算科目", trigger: "change" }
  ]
})
const dataForm = ref()
const list = ref([])
const loading = ref(false)
const code = ref([])
const settleName = ref('1001 库存现金')
const settleOptions = [{
  value: '1001 库存现金',
  label: '1001 库存现金'
},]
const hwOptions = [
  {
    value: 1,
    label: "普通服务"
  },
  {
    value: 2,
    label: "广告服务"
  },
  {
    value: 3,
    label: "娱乐服务"
  },
  {
    value: 4,
    label: "技术服务"
  },
  {
    value: 5,
    label: "加工承揽"
  },
  {
    value: 6,
    label: "财产租赁"
  },
  {
    value: 7,
    label: "建设工程勘察设计"
  },
  {
    value: 8,
    label: "货物运输"
  },
  {
    value: 9,
    label: "仓储保管"
  },
  {
    value: 10,
    label: "建筑安装工程承包"
  },
  {
    value: 11,
    label: "固定资产"
  },
  {
    value: 20,
    label: "营业账簿"
  },
  {
    value: 21,
    label: "货物"
  },
  {
    value: 22,
    label: "权利、许可证照"
  },
  {
    value: 23,
    label: "产权转移书据"
  },
  {
    value: 24,
    label: "借款合同"
  },
  {
    value: 25,
    label: "融资租赁合同"
  },
  {
    value: 26,
    label: "财产保险"
  }
]
const init = (item) => {
  list.value = item
  reset()
  dialogVisible.value = true
}
const reset = () => {
  form.value = {
    jsFs: list.value.length == 1 ? 1 : 2
  }
  changeSettle()
}
//修改大类
const changeBig = () => {
  form.value.smId = null
  if (form.value.ssflbm) {
    if (list.value[0].type == '1') {
      //31简易计税方法-1%征收率的货物及加工修理修配劳务
      //32简易计税方法-1%征收率的服务、不动产和无形资产
      form.value.smId = ['10', '20'].includes(form.value.ssflbm) ? 31 : 32
    }
    if (list.value[0].type == '2') {
      //30一般计税方法-13%税率的货物
      //6一般计税方法-13%税率的加工修理修配劳务
      //14一般计税方法-6%税率
      form.value.smId = form.value.ssflbm == '10' ? 30 : form.value.ssflbm == '20' ? 6 : 14
    }
  }
}
const changeSettle = () => {
  form.value.subjectId = 0;
  form.value.fzhsId = 0
  code.value = form.value.jsFs == 2 ? ['code1001'] : ['code1002']
}
const saveTicket = () => {
  dataForm.value.validate((valid) => {
    if (valid) {
      //if (list.value.length == 1 && isNaN(Number(form.value.hsJe))) return ElMessage.warning('请输入正确的含税金额')
      ///if (list.value.length > 1 && (isNaN(Number(form.value.hsJeSt)) || isNaN(Number(form.value.hsJeEd)))) return ElMessage.warning('请输入正确的含税金额')
      if (list.value.length > 1 && (Number(form.value.hsJeSt)) > Number(form.value.hsJeEd)) return ElMessage.warning('请输入正确的含税金额区间')
      loading.value = true
      form.value.comIds = list.value.map(item => item.comId)
      form.value.period = props.period
      batchWpsr(form.value).then(res => {
        loading.value = false
        if (res.data.msg == 'success') {
          ElMessage.success('操作成功')
          dialogVisible.value = false
        }
      })
    }
  })
}
defineExpose({ init })
</script>

<style scoped lang="scss">
.w-114 {
  width: 114px;
}
</style>
