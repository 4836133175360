<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible" title="发票采集" class="button_bg" width="30%">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="进销项采集" name="进销项采集"></el-tab-pane>
      <el-tab-pane label="进项采集" name="进项采集"></el-tab-pane>
      <el-tab-pane label="销项采集" name="销项采集"></el-tab-pane>
    </el-tabs>
    <div class="collect">
      <div class="always">
        <p class="top">
          <img class="logo" src="@/assets/taxLogo.webp" alt="">
          <span class="zzs">税局数字账户</span>
          <span class="recommend" v-if="activeName == '进项采集' && this.fpdkIn == 1">推荐</span>
          <span class="recommend" v-if="activeName == '销项采集' && this.fpdkOut == 1">推荐</span>
          <span class="recommend" v-if="activeName == '进销项采集' && this.allPt == 1">推荐</span>
        </p>
        <!-- <el-checkbox v-if="activeName == '进项采集' || activeName == '进销项采集'" class="center" v-model="listQuery.cond.wrz"
          :disabled="!fpdkInAll == 1">采集【未认证】进销项（仅限当月）</el-checkbox> -->
        <el-checkbox-group v-if="activeName == '进项采集' || activeName == '进销项采集'" v-model="wrzArr">
          <el-checkbox label="已认证专票" :disabled="type == '1'"></el-checkbox>
          <el-checkbox label="未认证专票">未认证专票(当月)</el-checkbox>
          <el-checkbox label="普票"></el-checkbox>
        </el-checkbox-group>
        <el-checkbox class="jd" v-if="this.type == '1' && xgmJdCj" v-model="zzsJd">小规模季度采集</el-checkbox>
        <div class="footer">
          <div style="color: #cd1a1a"></div>
          <div>
            <el-button v-if="activeName == '进项采集'" type="primary" size="small" @click="begin('增值税发票服务平台')" :loading="inLoading"
              :disabled="fpdkIn != 1">开始采集</el-button>
            <el-button v-if="activeName == '销项采集'" type="primary" size="small" @click="begin('增值税发票服务平台')" :loading="outLoading"
              :disabled="fpdkOut != 1">开始采集</el-button>
            <el-button v-if="activeName == '进销项采集'" type="primary" size="small" @click="begin('增值税发票服务平台')" :loading="allLoading"
              :disabled="fpdkIn != 1 || fpdkOut != 1">开始采集</el-button>
          </div>
        </div>
      </div>
      <!-- <div class="always1">
        <p class="top">
          <img class="logo" src="@/assets/taxLogo.webp" alt="">
          <span class="zzs">地区电子税局系统</span>
          <span class="recommend" v-if="activeName == '进项采集' && this.etaxIn == 1">推荐</span>
          <span class="recommend" v-if="activeName == '销项采集' && this.etaxOut == 1">推荐</span>
          <span class="recommend" v-if="activeName == '进销项采集' && this.allSj == 1">推荐</span>
        </p>
        <el-checkbox class="jd" v-if=" activeName == '销项采集' && this.type == '1'  && xgmJdCj" v-model="dzsjJd">小规模季度采集</el-checkbox>
        <div class="footer">
          <div>（<span style="color:#cd1a1a">*</span>采集数据仅限当月，注意 <span style="color:#cd1a1a">不含跨月认证、代开发票</span> ）</div>
          <div>
            <el-button v-if="activeName == '进项采集'" type="primary" size="small" @click="begin('地区电子税局系统')"
              :disabled="etaxIn != 1">开始采集</el-button>
            <el-button v-if="activeName == '销项采集'" type="primary" size="small" @click="begin('地区电子税局系统')"
              :disabled="etaxOut != 1">开始采集</el-button>
            <el-button v-if="activeName == '进销项采集'" type="primary" size="small" @click="begin('地区电子税局系统')"
              :disabled="etaxIn != 1 || etaxOut != 1">开始采集</el-button>
          </div>
        </div>
      </div> -->
      <!-- <div class="always1">
          <div class="hint">本地区不支持采集</div>
          <p class="top">
            <img class="logo" src="@/assets/taxLogo.webp" alt="">
            <span class="zzs">地区电子税局系统</span>
          </p>
          <div class="footer">
            <div>（*采集数据仅限当月，注意跨月认证）</div>
            <div><el-button type="info" disabled size="small">开始采集</el-button></div>
          </div>
        </div> -->
      <!-- <div class="alwaysT" v-if="this.activeName == '销项采集'">
        <p class="top"><span class="zzs">税控机柜系统采集</span></p>
        <el-checkbox class="jd" v-model="listQuery1.cond.jd">季度采集</el-checkbox>
        <div class="footer">
          <el-select v-model="value" placeholder="请选择机柜品牌" size="small">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <div>
            <el-button type="primary" size="small" @click="beginCJ('ruleForm')">开始采集</el-button>
          </div>
        </div>
        <el-form ref="ruleForm" :model="listQuery1" :rules="rules" label-width="60px" style="width: 50%;"
          v-if="this.value == '票通云'">
          <el-form-item label="用户名:" prop="ibmUser">
            <el-input v-model="listQuery1.ibmUser" size="small" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="ibmPass">
            <el-input v-model="listQuery1.ibmPass" size="small" placeholder="请输入密码"></el-input>
          </el-form-item>
        </el-form>
      </div> -->
    </div>

  </el-dialog>
</template>

<script>
import { invoiceCollection } from "@/api/collection"
import { sendTask } from "@/api/collection"
import { invoiceCollectionType } from "@/api/invoice"
export default {
  name: 'collectGather',
  props: {

  },
  data() {
    return {
      dialogFormVisible: false,
      tableData: [],
      contentStyleObj: {}, //高度变化
      taskName: '',
      activeName: '进销项采集',

      listQuery: {
        comIds: [],
        period: '',
        taskName: '',
        cond: {
          mode: '',
          invoiceType: '',
          wrz: ['已认证专票'],
          jd: false,
          ibmBrand: '',
          ibmUser: '',
          ibmPass: '',
        }
      },
      listQuery1: {
        comIds: [],
        period: '',
        taskName: '',
        cond: {
          mode: '',
          invoiceType: '',
          wrz: false,
          jd: false,
          ibmBrand: '',
          ibmUser: '',
          ibmPass: ''
        }
      },
      options: [{
        value: '企账房',
        label: '企账房'
      }, {
        value: '江苏云票儿',
        label: '江苏云票儿'
      }, {
        value: '票通云',
        label: '票通云'
      }],
      value: '',
      rules: {
        ibmUser: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        ibmPass: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },
      fpdkIn: 0,
      fpdkOut: 0,
      etaxIn: 0,
      etaxOut: 0,
      fpdkInAll: 0,
      type: undefined,
      xgmJdCj:false,
      address:'',
      zzsJd: false,
      zzsJd1:false,  //电子税务局采集
      dzsjJd:false,
      wrzArr:[],
      inLoading:false,
      allLoading:false,
      outLoading:false
    }
  },
  methods: {
    //弹窗
    init(comId, period, type, address) {
      // 重新点开做一下清空
      this.listClear1()
      this.address = address
      this.type = type
      this.listQuery.comIds = comId
      this.listQuery.period = period
      if(this.type == 1){
        this.listQuery.cond.wrz = ['未认证专票','普票']
        this.wrzArr = ['未认证专票','普票']
      }else if(this.type == 2){
        this.listQuery.cond.wrz = ['已认证专票','普票']
        this.wrzArr = ['已认证专票','普票']
      }
      if(period.slice(4,6) == '03' || period.slice(4,6) == '06' || period.slice(4,6) == '09' || period.slice(4,6) == '12'){
        this.xgmJdCj = true
      }else{
        this.xgmJdCj = false
      }
      if (!comId || !period) {
        this.$qzfMessage("信息错误", 1)
        return
      }
      invoiceCollectionType({ address: address }).then(res => {
        this.fpdkIn = res.data.data.fpdkIn
        this.fpdkOut = res.data.data.fpdkOut
        this.etaxIn = res.data.data.etaxIn
        this.etaxOut = res.data.data.etaxOut
        this.fpdkInAll = res.data.data.fpdkInAll
        this.dialogFormVisible = true
      })

    },
    listClear1(){
      this.listQuery.comIds = [],
      this.listQuery.period = '',
      this.listQuery.taskName = '',
      this.listQuery.cond.mode = '',
      this.listQuery.cond.invoiceType = '',
      this.listQuery.cond.wrz = ['已认证专票'],
      this.listQuery.cond.jd = false,
      this.listQuery.cond.ibmBrand = '',
      this.listQuery.cond.ibmUser = '',
      this.listQuery.cond.ibmPass = ''
      this.dzsjJd = false
      this.zzsJd = false

    },
    //清空
    listClear() {
      this.listQuery1.comIds = [],
        this.listQuery1.period = '',
        this.listQuery1.cond.mode = '',
        this.listQuery1.cond.type = '',
        this.listQuery1.cond.wrz = false,
        this.listQuery1.cond.jd = false,
        this.listQuery1.cond.ibmBrand = '',
        this.listQuery1.cond.ibmUser = '',
        this.listQuery1.cond.ibmPass = ''
    },
    begin(e) {
      this.listQuery.cond.mode = e
      this.listQuery.cond.invoiceType = this.activeName
      if (this.activeName == "进销项采集") {
        this.listQuery.taskName = 'tax-cj-invoice'
        this.allLoading = true
      } else if (this.activeName == "进项采集") {
        this.listQuery.taskName = 'tax-cj-inInvoice'
        this.inLoading = true
      } else if (this.activeName == "销项采集") {
        this.listQuery.taskName = 'tax-cj-outInvoice'
        this.outLoading = true
      }
      // 小规模季度采集
      if(this.dzsjJd || this.zzsJd){
        this.listQuery.cond.jd = true
      }else{
        this.listQuery.cond.jd = false
      }
      // 后端做判断了
      // 小规模季度进销项
      // if(this.listQuery.cond.wrz.length == 0 && this.xgmJdCj && !this.zzsJd && this.activeName !="销项采集"){
      //   this.$qzfMessage('请至少选择一种方式', 2)
      //   return
      // }
      // 进销项
      // if(this.listQuery.cond.wrz.length == 0 && !this.xgmJdCj && this.activeName !="销项采集"){
      //   this.$qzfMessage('请至少选择一种方式', 2)
      //   return
      // }
      // 小规模季度销项
      // 小规模销项没有限制
      // if(this.xgmJdCj && !this.zzsJd && this.activeName == "销项采集" && this.type != 2){
      //   this.$qzfMessage('请至少选择一种方式', 2)
      //   return
      // }
      // this.wrzArr = this.listQuery.cond.wrz
      this.listQuery.cond.wrz = this.wrzArr.toString()
      // if (this.listQuery.cond.wrz.indexOf("已认证专票") != -1){
      //   this.$notify({
      //     title: '提示',
      //     message: '因税局变动,已认证专票现采集不到明细,预计一两个工作日完成更新',
      //     type: 'warning',
      //     duration: 0,
      //   })
      // }
      sendTask(this.listQuery).then(res => {
        if (this.activeName == "进销项采集") {
          this.allLoading = false
        } else if (this.activeName == "进项采集") {
          this.inLoading = false
        } else if (this.activeName == "销项采集") {
          this.outLoading = false
        }
        if (res.data.msg == 'success') {
          this.$qzfMessage('任务已发起', 3)
          this.$emit("success")
          this.dialogFormVisible = false
          // this.listQuery.cond.wrz  = this.wrzArr
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    beginCJ(ruleForm) {
      if (this.value == '票通云') {
        this.$refs[ruleForm].validate((valid) => {
          if (valid) {
            // this.listClear()
            this.listQuery1.cond.mode = '税控机柜'
            this.listQuery1.cond.ibmBrand = this.value
            this.listQuery1.cond.invoiceType = this.activeName
          }
        });
      } else {
        // this.listClear()
        this.listQuery1.cond.mode = '税控机柜'
        this.listQuery1.cond.ibmBrand = this.value
        this.listQuery1.cond.invoiceType = this.activeName
        invoiceCollection(this.listQuery1).then(res => {

        })
      }

    },
    handleClick(tab){
      if (this.activeName == "进销项采集") {
        this.allLoading = false
      } else if (this.activeName == "进项采集") {
        this.inLoading = false
      } else if (this.activeName == "销项采集") {
        this.outLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: right;
  margin-top: 10px;
}

.always {
  background-color: #e7e7e759;
  padding: 10px;
  margin-bottom: 20px;

  .top {
    padding: 10px;

    .zzs {
      font-size: 15px;
      font-weight: 500;
      color: #000;
    }
  }

  .center {
    padding: 10px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
}

.recommend {
  color: white;
  background-color: #cd1a1a;
  border-radius: 6px;
  padding: 1px 2px;
  margin-left: 20px;
}

.always1,
.alwaysT {
  background-color: #e7e7e759;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;

  .hint {
    position: absolute;
    right: 1px;
    top: 0;
    background-color: #b5b0b0;
    color: white;
    padding: 0 8px;
  }

  .top {
    padding: 10px;

    .zzs {
      font-size: 15px;
      font-weight: 500;
      color: #000;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
}

.logo {
  width: 20px;
  vertical-align: bottom;
  padding-right: 5px;
}
</style>